import React from 'react';
import Form from  '../components/Form';


export default function Enroll2(props) {
    return <Form form={ FORMITEMLIST }/>
}

//立即报名
const FORMITEMLIST = {
  type:'enroll2',tabs:[
    {label:'立即报名',type:'enroll2'},
    {label:'我的报名',type:'myEnroll'}
  ],
  className:'middle-label',
  list:[
    {label:'出版物名称',placeholder:'请填写出版物名称',required:true,name:'bookName',type:'input',maxLength:255},
    //{label:'ISBN',placeholder:'请填写ISBN',required:true,name:'isbn',type:'input',maxLength:100},
    //{label:'出版物类型',placeholder:'请填写出版物类型',required:true,name:'bookType',type:'input',maxLength:50},
    {label:'印刷时间',placeholder:'请填写印刷时间',name:'printDate',required:true,type:'input',type2:'date'},
    {label:'发行量',placeholder:'请填写发行量',name:'publishNum',required:true,type:'input',type2:'number',maxLength:6},
    //{label:'出版物简介',placeholder:'出版物简介',required:true,name:'bookIntroduction',type:'textarea',maxLength:1000},
    //{label:'作者及编辑简介',placeholder:'作者及编辑简介',required:true,name:'adviserIntroduction',type:'textarea',maxLength:1000},
    {label:'',margin:'30px'},
    //{label:'提交',name:'stepbtn',type:'button',type2:'enroll',params:''},
    {label:'',name:'array',type:'array',list:[
      {label:'上一步',name:'stepbtn',type:'button',type2:'prevStep',params:'enroll'},
      {label:'提交',name:'stepbtn',type:'button',type2:'enroll',params:''},
    ]}
  ]
}